var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Adicionar Documentação Técnica de Apoio")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Área reservada à adição de documentação técnica de Apoio para a plataforma. ")]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5","justify":"center","align":"center"}},[(
          [4, 5, 6, 7].includes(this.$userLevel()) &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/documentacaoApoio/criar/classe')}}},[_c('unicon',{attrs:{"name":"criar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.721","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Adicionar Secção")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"7","justify":"center","align":"center"}},[(
          [4, 5, 6, 7].includes(this.$userLevel()) &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/documentacaoApoio/criar/tecnico_cientifico')}}},[_c('unicon',{attrs:{"name":"criar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.721","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Adicionar Documento Técnico/Científico")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }